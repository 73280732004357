<template>

  <modal :loading="loading" titulo="Gestão de Ato" class="modal-detalhes-ato" id="detalhes-ato" :fechar="true" @close="close">

    <template #actions>

    </template>

    <template>

      <div id="aba-dados-principais">
        <card>
          <div class="row gutters-xs ato">

            <v-input :default="true" v-model="form.custasManual"
                     title="Ao marcar essa opção como sim, este ato não terá mais seus valores cálculados pelo sistema em nenhuma operação"
                     label="Bloquear alterações nos emolumentos e custas deste ato?" type="radio" :class="form.tipoServico && form.tipoServico.opcoes && form.tipoServico.opcoes.opcionalIncluirRelatorioIR ? 'col-6 text-red' : 'col-12 text-red'"/>

            <v-input type="radio" label="Incluir no relatório do indicador real?"
                     v-model="form.dto.incluirAtoRelatorioIR" :default="false" class="col-4"  v-if="form.tipoServico && form.tipoServico.opcoes && form.tipoServico.opcoes.opcionalIncluirRelatorioIR && form.ficha.livro !== 'REGISTRO_AUXILIAR'" />

            <div class="w-100" />

            <template>
              <v-input :error="validation.tipoServico" :options="tiposServico"
                       @change="changeTipo" class="col-5" label="Tipo de Ato" type="v-select"
                       v-model="form.tipo_id"/>
              <v-input :error="validation.titulo" class="col-4" label="Titulo"
                       type="text" v-model="form.titulo"/>
              <v-input :error="validation.codigo" class="col-3" label="Código"
                       type="number" v-model="form.codigo"/>
            </template>

            <div class="w-100"></div>
            <v-input :error="validation.registro" class="col-4" label="Registro"
                     type="datetime-local" v-model="form.registro" :max="today" @blur="checkDate('registro')"/>

            <v-input :error="validation.selado" class="col-4" label="Selagem"
                     type="datetime-local" v-model="form.selado" :max="today" @blur="checkDate('selado')"/>

            <div class="col">
              <label>Protocolo</label>
              <buscar-protocolos :multiple="false" @selecionar="selecionar($event)"
                                 :dominio="dominio"
                                 :tipoProtocoloSimples="true" ref="buscarProtocolos" v-if="!form.protocolo"/>
              <div class="row gutters-xs" v-else>
                <v-input class="col form-group">
                  <input class="form-control form-control-xs" readonly type="text" v-model="form.protocolo.codigo"/>
                </v-input>

                <div class="col-auto">
                  <button :disabled="false" @click="selecionar(null)"
                          class="btn btn-outline-secondary btn-xs" title="Remover" type="button">
                    <i class="fal fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            <template v-if="form.legado && form.id">
              <div class="w-100"/>
              <card class="w-100">
                <div class="row gutters-xs">
                  <v-input v-model="form.livro" type="select" :options="tipoStatus" :disabled="!!form.ficha"
                           :required="true" placeholder="Tipo" style="width: 21%" />
                  <buscar-fichas :livro="selecionadoFicha.livro" :status="selecionadoFicha.status" ref="buscarFichas"
                                 @selecionar="selecionarFichas" @pesquisando="pesquisandoFicha" :multiplos="false" :multiple="false" class="col-9" :full="true"/>

                  <div class="row gutters-xs col">
                    <div class="col-auto">
                      <button :disabled="false" @click="selecionarFichas(null)"
                              class="btn btn-outline-secondary btn-xs" title="Remover" type="button">
                        <i class="fal fa-times"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </card>
            </template>

            <div class="w-100"/>
            <card v-if="!loading" class="w-100" titulo="Minuta">

              <template #acoes v-if="form.tipo_id">

                <v-button title="Gerar texto com base na configuração"
                          :button="false" :popover="true" position="dropleft"
                          :run="gerarTexto" class="icon float-right pointer">
                  GERAR TEXTO
                </v-button>

                <v-button :button="false" :run="consultarModelos" class="icon float-right pointer mr-3">
                  CONSULTAR MODELOS
                </v-button>

                <v-button :button="false" :run="consultarQualificacoes" class="icon float-right pointer mr-3">
                  QUALIFICAÇÕES
                </v-button>

              </template>
              <v-input v-model="form.texto"
                       ref="editorminuta"
                       type="classic-editor" class="col p-0 pb-2" />
            </card>

            <div class="w-100"></div>
            <v-input v-if="ficha.imovelUniao" class="col mb-3"
                     v-model="form.numeroCat" type="mask" :masks="['###################']" label="Número da CAT" />
            <div class="w-100"></div>
            <vinculo-sicase class="w-100" :item="form" :edicao="true" tipo="ato" />

            <div class="col-12">
              <card titulo="DOI" v-if="possuiAreaDoi">
                <v-input :default="false" v-model="form.doi.gerarDoi" label="Gerar DOI?" type="radio" />
              </card>
            </div>

            <div class="w-100"></div>
            <template v-if="form.tipo_id">
              <div class="w-100">
                <component  v-for="area in areas" :ato="form" :ficha="ficha" :protocolo="form.protocolo"
                            @checklist="atualizarChecklists()" :edicao="true" @change-editor="changeEditor"
                            :key="area.id" :is="area.id" :ref="area.id" :id="area.id" :checklist="checklistEnvolvidos">
                </component>
              </div>
            </template>

            <div class="w-100"></div>

            <responder-checklist :documentos="documentos" v-if="form && form.id && checklist && checklist.length"
                                 :checklists="checklist"
                                 :permitir-edicao="true"
                                 :dto="form"
                                 ref="checklist"
                                 entidade="ato"
            />

          </div>
        </card>
      </div>
    </template>

    <template #footer>
      <button @click.prevent="close()" class="btn btn-outline-secondary mr-auto" type="button">Fechar</button>
      <v-button :run="salvar" class="btn-outline-primary" :disabled="realizandoBusca">Salvar & Continuar</v-button>
    </template>

  </modal>

</template>

<script>


import ChecklistProcessamentoBusiness from "@/business/ChecklistProcessamentoBusiness.js";
import TipoServicoBusiness from "@/business/crud/TipoServicoBusiness";
import FrontBusiness from "@/business/FrontBusiness.js";
import AtoLegadoBusiness from "@/business/indicadores/AtoLegadoBusiness.js";
import IndicadorBusiness from "@/business/indicadores/IndicadorBusiness.js";
import IndicadorPessoalBusiness from "@/business/indicadores/IndicadorPessoalBusiness";
import IndicadorRealBusiness from "@/business/indicadores/IndicadorRealBusiness";
import RegistroAuxiliarBusiness from "@/business/indicadores/RegistroAuxiliarBusiness";
import textoFormatado from "@/business/livros/copiladores/textoFormatado.js";
import AtoBusiness from "@/business/protocolo/AtoBusiness";
import ProtocoloDocumentoBusiness from "@/business/protocolo/ProtocoloDocumentoBusiness";
import RegraCustasBusiness from "@/business/RegraCustasBusiness.js";
import Utils from "@/commons/Utils.js";
import BuscarProtocolos from "@/components/BuscarProtocolos";
import TagList from "@/components/Form/TagList";
import Card from "@/components/Layout/components/Card.vue";
import CardTable from "@/components/Layout/components/CardTable.vue";
import Modal from "@/components/Modal";
import VinculoSicase from "@/components/VinculoSicase.vue";
import ModalSelecionarModeloTextual from "@/views/Administracao/modulos/modelo-textual/ModalSelecionarModeloTextual";
import tipoServicoBusiness from "@/views/Administracao/modulos/tipo-servico/business";
import BensGarantias from "@/views/Protocolo/Atos/Ato/BensGarantias.vue";
import CancelarRestricoes from "@/views/Protocolo/Atos/Ato/CancelarRestricoes";
import CancelarRestricoesNaoRegistradas from "@/views/Protocolo/Atos/Ato/CancelarRestricoesNaoRegistradas";
import Documentos from "@/views/Protocolo/Atos/Ato/Documentos.vue";
import EditarIndicadorReal from '@/views/Protocolo/Atos/Ato/EditarIndicadorReal'
import EditarRegistroAuxiliar from '@/views/Protocolo/Atos/Ato/EditarRegistroAuxiliar'
import Envolvidos from "@/views/Protocolo/Atos/Ato/Envolvidos";
import ModalInserirQualificacao from "@/views/Protocolo/Atos/Ato/ModalInserirQualificacao";
import MultiplasCobrancas from "@/views/Protocolo/Atos/Ato/MultiplasCobrancas";
import Recolhimentos from "@/views/Protocolo/Atos/Ato/Recolhimentos.vue";
import RestricoesCard from "@/views/Protocolo/Atos/Ato/Restricoes/RestricoesCard";
import business from "@/views/Protocolo/Atos/business.js";
import ModalCompararTexto from "@/views/Protocolo/Atos/ModalCompararTexto";
import moment from "moment";

export default {

  name: "DetalhesAto",
  components: {
    Modal,
    BuscarProtocolos,
    CardTable,
    Card,
    TagList,
    /** ÁREAS **/
    VinculoSicase,
    CancelarRestricoes, Envolvidos,
    RestricoesCard, EditarIndicadorReal,
    EditarRegistroAuxiliar, Recolhimentos,
    CancelarRestricoesNaoRegistradas, MultiplasCobrancas, Documentos, BensGarantias,
    ResponderChecklist: () => import("@/components/Protocolo/ResponderChecklist.vue"),
    Doi: () => import("@/views/Protocolo/Atos/Ato/DOI"),
    LivroEstrangeiro: () => import("@/views/Protocolo/Atos/Ato/LivroEstrangeiro.vue"),
    Emolumentos: () => import("@/views/Protocolo/Atos/Ato/Emolumentos"),
    CardAtosIndicador: () => import("@/components/IndicadorReal/Detalhes/CardAtosIndicador"),
  },

  restricoesDisponiveis: [],


  modal: {
    width: 1100,
    escapable: false
  },

  props: {
    id: String,
    protocoloId: String,
    ficha: Object,
  },

  async created() {

    await ChecklistProcessamentoBusiness.init();
    this.checklists = window.checklists;
    this.listas = window.listas;

    await TipoServicoBusiness.getByDominioSimplesTodos(this.dominio).then(l => l.forEach(n => {
      n.opcoes?.livros?.forEach(l => {
        if (l === this.ficha.livro) this.tiposServico?.push(n)
      });
    }));

    let dto = {
      atosVirtuais: [],
      envolvidos: [],
      tipoServico: {opcoes: {}},
      legado: true,
      ordem: 99999,
      dto: {camposAdicionais: {}, valoresBaseCalculo: {}},
      doi: {gerarDoi: false}
    };

    if (this.id) {

      let promises = [];

      if (this.protocoloId) {
        promises.push(ProtocoloDocumentoBusiness.listar(this.protocoloId).then(l => this.documentos = l));
      }

      dto = await AtoBusiness.getById(this.protocoloId, this.id).catch(e => {
        FrontBusiness.showError('', e.message)
        this.close()});

      await Promise.all(promises);

      dto.isento = !!dto.isento;

      if(dto?.id){
        dto.envolvidos = dto.envolvidos || [];
        dto.tipoServico = dto.tipoServico || {};
        dto.tipo_id = dto.tipoServico?.id || [];
        if(dto.registro){
          dto.registro = this.$moment(dto.registro).format('YYYY-MM-DDTHH:mm')
        }

        if(dto.selado){
          dto.selado = this.$moment(dto.selado).format('YYYY-MM-DDTHH:mm')
        }
        dto.dto = dto.dto || {};
        dto.dto.configuracaoRestricao = dto.dto.configuracaoRestricao || {};
        dto.dto.restricoesCanceladas = dto.dto.restricoesCanceladas || [];
        dto.legado = dto.legado === null ? true : dto.legado;
        dto.dto.incluirAtoRelatorioIR = dto.dto.incluirAtoRelatorioIR || false;


        await this.setAreas(dto);
      }
    }
    dto.doi = this.getDoiDTO(dto);
    dto.dto.camposAdicionais = await this.getCamposAdicionais(dto);
    dto.dto.indicador = dto.dto.indicador || {};
    dto.ficha = this.ficha || {};
    this.$set(this, 'form', dto);

    if (this.$refs.buscarFichas) {
      this.$refs.buscarFichas.individual = this.ficha;
      this.$refs.buscarFichas.strId = this.ficha.id;
    }

    // await this.getProcurador();
    await this.tratarChecklist();
    this.$nextTick(() => {
      setTimeout(() => {
        this.verificarAlteracoes(true);
      }, 150);
    });
    this.$set(this, 'loading', false);
  },

  watch: {
    envolvidos: function () {
      this.atualizarCardLivroEstrangeiro();
    },
    papeisEnvolvidos: function () {
      this.atualizarCardLivroEstrangeiro();
    }
  },

  computed: {
    papeis() {
      const proibidos = this.form?.tipoServico?.opcoes?.papeisProibidos || [];
      return IndicadorRealBusiness.getPapeisEnvolvidos().filter(p => !proibidos.includes(p.id))
    },
    envolvidos() {
      return this.form.envolvidos;
    },
    papeisEnvolvidos() {
      return this.form.envolvidos.map(e => e.papel);
    },
    possuiAreaDoi() {
      return this.areas.filter(a => a.id === 'doi').length > 0 && !this.form.tipoServico?.opcoes?.doiOpcional && this.form.tipo_id != null;
    },
    today(){
      return new Date().toISOString().split(".")[0];
    },
    tipoStatus() {
      const livro = IndicadorBusiness.getLivro(this.ficha?.livro);
      return [{
        id:  livro.id,
        nome: livro.nome,
        livro:  livro.id,
        status: 'ABERTA'
      }, this.ficha?.livro == 'TRANSCRICAO' ? null : {
        id: "PRE_"+livro.id,
        nome: "Pré-"+livro.nome,
        livro:  livro.id,
        status: 'PENDENTE'
      }].filter(t => t);
    },
    selecionadoFicha() {
      return this.tipoStatus.find(e => e.id === this.form.livro) || {};
    },

    realizandoBusca() {
      if (this.form.legado && this.form.id) {
        return this.pesquisando;
      } else {
        return false
      }
    },

    dominio() {
      return IndicadorBusiness.getDominioByLivro(this.ficha.livro)
    }
  },

  data() {
    return {
      form: {
        atosVirtuais: [],
        envolvidos: [],
        tipoServico: {opcoes: {}},
        dto: {incluirAtoRelatorioIR: false, doi: {gerarDoi: false}, camposAdicionais: {}, valoresBaseCalculo: {}},

      },
      form_old: {},
      papeisComFracao: ['ADQUIRENTE', 'TRANSMITENTE', 'DEVEDOR','GARANTIDOR', 'CREDOR', 'CEDENTE', 'CESSIONARIO', 'PROMITENTE_VENDEDOR', 'PROMITENTE_COMPRADOR', 'FIEL_DEPOSITARIO'],
      tiposServico: [],
      checklists: [],
      documentos: [],
      listas: [],
      indices: {},
      checklist: [],
      checklistEnvolvidos: [],
      validation: {},
      mostrarDataVencimento: false,
      areas: [],
      atualizarAreas: true,
      loading : true,
      areasPossiveis: ['envolvidos','cancelar-restricoes-nao-registradas', 'cancelar-restricoes', 'restricoes-card', 'card-atos-indicador', 'livro-estrangeiro', 'doi', 'emolumentos', 'editar-indicador-real', 'multiplas-cobrancas', 'recolhimentos', 'documentos', 'bens-garantias'],
      areasPossiveisRegistroAuxiliar: ['envolvidos','cancelar-restricoes-nao-registradas', 'cancelar-restricoes', 'restricoes-card', 'card-atos-indicador', 'doi', 'emolumentos', 'editar-registro-auxiliar', 'multiplas-cobrancas'],
      alterouFicha: false,
      pesquisando: false
    }
  },

  methods: {

    async setAreas(ato) {
      ato.documentos = ato.documentos || [];
      ato.indicadorGarantias = ato.indicadorGarantias || [];
      ato.tipoServico = ato.tipoServico || this.form.tipoServico;
      ato.livro = ato.livro || this.ficha.livro;
      let areas = business.areasAto(ato);
      ato.dto = ato.dto || {};
      ato.dto.valoresBaseCalculo = ato.dto?.valoresBaseCalculo || {};
      ato.dto.configuracaoRestricao = ato.dto.configuracaoRestricao || {};
      ato.dto.restricoesCanceladas = ato.dto.restricoesCanceladas || [];
      ato.dto.incluirAtoRelatorioIR = ato.dto.incluirAtoRelatorioIR || false;
      switch (this.ficha.livro) {
        case 'REGISTRO_AUXILIAR':
          ato.registroAuxiliar = ato.registroAuxiliar || this.form.ficha;
          if (!ato.id) {
            ato.dto.restricoes = await RegistroAuxiliarBusiness.restricoesVigentes(ato.registroAuxiliar.id);
          }
          break;
        case 'MATRICULA':
        case 'TRANSCRICAO':
          ato.indicadorReal = ato.indicadorReal || this.form.ficha;
          if (!ato.id) {
            ato.dto.restricoes = await IndicadorRealBusiness.restricoesVigentes(ato.indicadorReal.id);
          }
          break;
        default:
          ato.indicador = ato.indicador || this.form.ficha;
          break;
      }
      ato.doi = this.getDoiDTO(ato);
      ato.dto.camposAdicionais = await this.getCamposAdicionais(ato);
      this.filtrarAreas(areas);
      this.$set(this, 'form', ato);

    },

    filtrarAreas(areas) {
      this.areas = areas;
      this.areas = areas.filter(a => ((this.ficha.livro !== 'REGISTRO_AUXILIAR' && this.areasPossiveis.includes(a.id))
        || (this.ficha.livro === 'REGISTRO_AUXILIAR' && this.areasPossiveisRegistroAuxiliar.includes(a.id))));
    },

    close(){
      FrontBusiness.closeModal(this.alterouFicha)
    },

    selecionar(protocolo) {
      this.$set(this.form, 'protocolo', protocolo ? protocolo[0] : null);
    },

    remover(idx, lista) {
      this.$nextTick(() => {
        this.form[lista].splice(idx, 1);
      });
    },

    async changeTipo(id) {
      let tipoServico = this.tiposServico.find(e => e.id === id);
      this.$set(this.form, 'tipoServico', tipoServico);
      //this.$set(this.form, 'titulo', tipoServico?.opcoes?.titulo);
      this.$set(this, 'areas', []);
      await this.setAreas(this.form);
    },

    showError(e) {
      FrontBusiness.fullErrorHandler(this, e, '#formulario-ato');
      return null;
    },

    validarRestricoes(dto) {
      let retorno = true;
      let erroPapelEnvolvido = tipoServicoBusiness.verificarPapeisEnvolvidos(dto.envolvidos, dto.tipoServico?.opcoes?.papeisObrigatorios);
      if (erroPapelEnvolvido) {
        FrontBusiness.showError('', erroPapelEnvolvido);
        retorno = false;
      }

      if(dto.tipoServico?.opcoes?.restricaoOpcional){
        if(!dto.tipoServico?.opcoes?.criarRestricao){
          FrontBusiness.showError('', `Necessário configurar as restrições para o tipo de ato selecionado`);
          retorno = false;
        }

        const c1 = dto?.dto?.configuracaoRestricao?.restricoesSelecionadas?.find(a => ['ONUS', 'ACAO', 'INDISPONIBILIDADE'].includes(a));
        const restricao_acao = dto.dto?.configuracaoRestricao?.restricoesSelecionadas?.find(r => r === 'ACAO');
        const papeis_obrigatorios = [...['DEVEDOR', 'EMITENTE', 'CREDOR', 'GARANTIDOR', 'FIEL_DEPOSITARIO'], ...(restricao_acao ? ['AUTOR', 'REU'] : [])];
        const pObrigatorios1 = dto.tipoServico?.opcoes?.papeisObrigatorios?.find(p => papeis_obrigatorios.includes(p));
        if(c1 && !pObrigatorios1 && !dto.envolvidos.find(e => papeis_obrigatorios.includes(e.papel))){
          FrontBusiness.showError('', `Necessário informar uma parte envolvida como Devedor, Emitente, Garantidor, Fiel Depositário${restricao_acao ? ', Credor, Autor ou Réu' : ' ou Credor'} para a criação da restrição`);
          retorno = false;
        }

          const c2 = dto.dto?.configuracaoRestricao?.restricoesSelecionadas?.find(a => ['COMPROMISSO'].includes(a));
          const pObrigatorios2 = dto.tipoServico?.opcoes?.papeisObrigatorios?.find(p => ['PROMITENTE_VENDEDOR', 'PROMITENTE_COMPRADOR'].includes(p));
          if(c2 && !pObrigatorios2 && !dto.envolvidos.find(e => ['PROMITENTE_VENDEDOR', 'PROMITENTE_COMPRADOR'].includes(e.papel))){
            FrontBusiness.showError('', 'Necessário informar uma parte envolvida como Promitente Transmitente ou Promitente Adquirente para a criação da restrição');
            retorno = false;
          }

          const c3 = dto.dto?.configuracaoRestricao?.restricoesSelecionadas?.find(a => ['DOMINIO'].includes(a));
          const pObrigatorios3 = dto.tipoServico?.opcoes?.papeisObrigatorios?.find(p => ['DEVEDOR'].includes(p));
          if(c3 && !pObrigatorios3 && !dto.envolvidos.find(e => ['DEVEDOR'].includes(e.papel))){
            FrontBusiness.showError('', 'Necessário informar uma parte envolvida como Devedor para a criação da restrição');
            retorno = false;
          }
        }

      return retorno;
    },

    validarLivroEstrangeiro(ato) {
      if (!ato.id && this.areas.filter(a => a.id === 'livro-estrangeiro').length === 0) {
        ato.observacaoLivroEstrangeiro = null;
        ato.livroEstrangeiro = null;
      }
    },

    isPactoAnteNupcial(ato){
      return this.ficha.livro === 'REGISTRO_AUXILIAR' && ato?.tipoServico?.opcoes?.tipoRegistro === 'PACTO';
    },

    async gerarDto(){

      let dto = {}, indices = {quantidade : 0, respondidos: 0}, exigencias = [];

      let checklistProcessado = await ChecklistProcessamentoBusiness.processarEntidade(this.form, this.checklist, 'ato', this.documentos, 'storage')

      if(checklistProcessado?.grupos?.length){
        indices = checklistProcessado.indices;
        indices.checklistProcessado = checklistProcessado.grupos; //bkp do checklist respondido
        exigencias = checklistProcessado.exigencias;
        indices.quantidade = checklistProcessado?.contador?.quantidade || 0;
        indices.respondidos = checklistProcessado?.contador?.respondidos || 0;
        exigencias = await ChecklistProcessamentoBusiness.copilarExigencias(this.protocolo, this.form, exigencias);
      }
      dto = Utils.clone({...this.form, indices, exigencias});
      delete dto.checklist;
      dto.indicadorDocumentos = dto.indicadorDocumentos?.map(e => {
        e.tipoDocumento = {id: e.tipoDocumento.id || e.tipoDocumento};
        return e;
      });
      dto.indicadorGarantias = dto.indicadorGarantias?.map(e => {
        e.bemMovel = {id: e.bemMovel.id};
        return e;
      });
      return Utils.clone(dto);

    },

    async salvar() {
      const MAX_LONG = 9223372036854775807;

      await this.$refs?.checklist?.process();

      let validated = true;
      this.$set(this, 'validation', {});
      if (!this.form?.ficha?.id) {
        this.$refs.buscarFichas.mensagemValidacao = 'Campo obrigatório'
      }

      let dto = await this.gerarDto();

      // let dto = Utils.clone(this.form);
      // dto.exigencias = await ChecklistProcessamentoBusiness.copilarExigencias(this.protocolo, this.form, this.form.exigencias);

      this.validarLivroEstrangeiro(dto);

      dto.tipo = dto.tipoServico?.opcoes?.tipoAto;
      dto.custasManual = dto.custasManual !== false ? true : false;

      dto.texto = textoFormatado(dto.texto);

      validated = this.validarRestricoes(dto);

      let obrigatorios = ['registro', 'codigo']; //, 'texto'
      if(!dto.legado){
        obrigatorios.push('tipoServico')
      }
      if ([undefined, null, ''].indexOf(dto['codigo']) === -1) dto['codigo'] = dto['codigo'] + '';
      obrigatorios.forEach(e => {
        if (!dto[e] || (Object.keys(dto[e]).length === 0 && dto[e].constructor === Object) || dto[e] === []) {
          this.$set(this.validation, e, 'Campo obrigatório');
          validated = false;
        }
      });
      let data = dto?.dto?.pacto;
      if (this.isPactoAnteNupcial(dto) && (Utils.isEmptyNullOrUndefined(data) || ((this.$moment(data).isAfter(this.$moment()) || !this.$moment(data).isValid() || data.split('-')[0] < '1850')))) {
        return FrontBusiness.showError('', 'Necessário informar uma data válida para o Pacto Antenupcial')
      }

      if (this.form.ficha.tipoImovel === 'OUTROS' && Utils.isEmptyNullOrUndefined(this.form.ficha.descricaoTipoImovelOutros) && !dto.legado) {
        return FrontBusiness.showError('', `Necessário informar a descrição do tipo de imóvel “Outros”`);
      }

      // dto.registro = new Date(dto.registro);
      // dto.selado = new Date(dto.selado);
      dto.livro = this.ficha?.livro;
      dto.protocolo = dto.protocolo?.id ? {id: dto.protocolo?.id} : null;
      dto.envolvidos = dto.envolvidos.length ? dto.envolvidos : null;

      if (dto.protocolo?.id) {
        dto.dto.salvandoItemProtocolo = true;
      }
      dto.envolvidos = dto.envolvidos?.map(e => {
        delete e.id;
        return e;
      });

      let countEnvolvidos = 0;
      let doitu = [];
      dto.envolvidos?.forEach(e => {
        let papelValido = this.papeis.find(p => p.id === e.papel);
        if (!papelValido) countEnvolvidos++;
        if(this.form.ficha.imovelUniao){
          e.representantes.forEach(representante=>{
            if(representante.documento && !representante.indicadorPessoalVersaoId){
              doitu.push("IMÓVEL DA UNIÃO [DOITU]: Necessário informar um nome para o representante ("+representante.documento +") do envolvido " + e.nome + ". Vincule um indicador pessoal no campo do representante")
            }
          })
        }
      })

      if (countEnvolvidos > 0) return FrontBusiness.showError('', `Necessário informar o papel do${countEnvolvidos > 1 ? 's' : ''} envolvido${countEnvolvidos > 1 ? 's' : ''}`)
      if (doitu.length) {
        doitu.map(msg => {
          FrontBusiness.showError('', msg)
        })
        return
      }

      //Validar doi
      let validatedDto = await this.validarDoi(dto);
      if (validated && validatedDto) {

        switch (this.ficha.livro) {
          case 'REGISTRO_AUXILIAR':
            dto.registroAuxiliar = {id: this.form.ficha.id};
            dto.legado = !dto.id ? true : dto.legado;
            break;
          case 'MATRICULA':
          case 'TRANSCRICAO':
            dto.indicadorReal = {id : this.form.ficha.id };
            break;
          default:
            dto.indicador = {id : this.form.ficha.id };
            break;
        }
        dto.tipoServico = dto.tipoServico?.id ? {id: dto.tipoServico?.id} : null;


        let atoId = await AtoLegadoBusiness.save(dto, this.form?.id).catch(this.showError);
        if (atoId) {
          FrontBusiness.showSuccess('', 'Ato salvo com sucesso');
          this.$set(this, 'alterouFicha', this.ficha.id != this.form.ficha.id);
          await this.verificarAlteracoes(true);
          if(!this.form?.id){
            this.$set(this.form, 'id', atoId);
          }
          return true;
        }
      }
    },

    atualizarCardLivroEstrangeiro() {
      if (this.areas.filter(a => a.id === 'livro-estrangeiro').length === 0 || !this.form.id) {
        const ato = Utils.clone(this.form);
        ato.envolvidos = ato.envolvidos || [];
        ato.ficha = this.form.ficha || {};
        const areas = business.areasAto(ato);
        this.filtrarAreas(areas);
      }
    },

    async validarDoi(ato) {

      if(!ato.tipoServico?.id){
        return true;
      }

      let doiValida = true;

      if (ato.ficha?.imovelUniao && ato.tipoServico?.opcoes?.tipoTransacaoDoi !== 'NENHUM' && ato.doi?.gerarDoi && Utils.isEmptyNullOrUndefined(ato.doi?.tipoTransacaoDoitu)){
        doiValida = false;
        FrontBusiness.showError('', `IMÓVEL DA UNIÃO [DOITU]: Necessário informar o tipo de transação do ato`);
      }

      let indicadorReal = IndicadorBusiness.getLivro(ato?.livro).grupo == 'INDICADOR_REAL';

      if (ato.tipoServico?.opcoes?.tipoTransacaoDoi === 'NENHUM' && indicadorReal && ato?.tipoServico?.opcoes?.tipoAto === 'ABERTURA') {
        try {
          await AtoLegadoBusiness.validarEnvolvidos(ato.envolvidos, ato);
        } catch (e) {
          FrontBusiness.showError('', e);
          doiValida = false;
        }
      }

      if (ato.tipoServico?.opcoes?.tipoTransacaoDoi !== 'NENHUM' && indicadorReal) {

        if (ato.doi?.gerarDoi) {

          ato.doi = ato.doi || {};

          if (!ato.doi?.situacaoConstrucao) {
            doiValida = false;
            FrontBusiness.showError('', `Necessário informar a situação da construção`);
          }

          if (!ato.doi?.formaAlienacao) {
            doiValida = false;
            FrontBusiness.showError('', `Necessário informar a forma de alienação para a DOI`);
          }

          if (!ato.doi?.dataAlienacao || (moment(ato.doi?.dataAlienacao).isAfter(moment()) || !moment(ato.doi?.dataAlienacao).isValid() || (ato.doi?.dataAlienacao && ato.doi?.dataAlienacao.split('-')[0] < '1850'))) {
            doiValida = false;
            FrontBusiness.showError('', `DOI - Necessário preencher o campo “Data da alienação” com uma data válida`);
          }

          if (ato.doi?.formaAlienacao === 'A_PRAZO' && (!ato.dto?.valoresBaseCalculo?.alienacao || ato.dto?.valoresBaseCalculo?.alienacao === '0')) {
            doiValida = false;
            FrontBusiness.showError('', `Necessário informar um valor de alienação para a DOI`);
          }

          if (ato.doi?.editarInformacao) {
            let campos = ['descricaoImovel'];
            if(ato.doi?.alienacaoConsta !== 'VALOR_NAO_CONSTA_NOS_DOCUMENTOS') {
              campos = [...campos, ...['valorAlienacao']];
            }
            if(ato.doi?.itbiConsta !== 'VALOR_NAO_CONSTA_NOS_DOCUMENTOS') {
              campos = [...campos, ...['valorAvaliacao']];
            }
            if(ato.doi?.areaConsta !== 'AREA_NAO_CONSTA_NOS_DOCUMENTOS') {
              campos = [...campos, ...['area']];
            }
            let camposVazios = campos.filter(key => (ato.doi[key] === null || ato.doi[key] === undefined));
            if (camposVazios.length) {
              doiValida = false;
            }
          }

          try {
            await AtoLegadoBusiness.validarEnvolvidos(ato?.envolvidos, ato);
          } catch (e) {
            FrontBusiness.showError('', e);
            doiValida = false;
          }

          for (let e of ato.envolvidos) {
            if(AtoLegadoBusiness.getPapeisTransmitente().includes(e.papel) || AtoLegadoBusiness.getPapeisAdquirente().includes(e.papel)) {
              if ((e.indicadorPessoalVersao?.documentoDesconhecido || (!e.indicadorPessoalVersao?.documentoDesconhecido && !e.indicadorPessoalVersao?.documento)) && !e.representantes) {
                if(ato.tipoServico.opcoes.permitirEnvolvidoSemDocumento){
                  FrontBusiness.showWarn('', `Necessário informar um ${e.tipoPessoa === 'FISICA' ? 'CPF' : 'CNPJ'} ou um representante para o envolvido ${e.nome}`)
                } else {
                  doiValida = false;
                  FrontBusiness.showError('', `Necessário informar um ${e.tipoPessoa === 'FISICA' ? 'CPF' : 'CNPJ'} ou um representante para o envolvido ${e.nome}`)
                }
              }
            }

            let msgDocInvalidoEnvolvido = await IndicadorPessoalBusiness.validarDocumentos(e.tipoPessoa, e.indicadorPessoalVersao?.documento);

            if(e.indicadorPessoalVersao?.documento && msgDocInvalidoEnvolvido) {
              doiValida = false;
              FrontBusiness.showError('', `O ${e.tipoPessoa === 'FISICA' ? 'CPF' : 'CNPJ'} informado para o envolvido ${e.indicadorPessoalVersao.nome} é inválido`);
            }
            if(e.representantes){
              for (const representante of e.representantes) {
                let msgDocInvalidoProcurador = await IndicadorPessoalBusiness.validarDocumentos('FISICA', representante.documento);
                if(msgDocInvalidoProcurador) {
                  doiValida = false;
                  FrontBusiness.showError('',  `O CPF (${representante.documento}) do representante informado para o envolvido ${e.indicadorPessoalVersao.nome} é inválido`);
                }
              }
            }

          }

        }

      } else {
        ato.doi = {};
        this.$set(this.form, 'doi', {});
      }

      return doiValida;

    },

    getStaticDoi() {
      return {gerarDoi: true};
    },

    getDoiDTO(ato) {
      let possuiAreaDoi = business.areasAto(ato).filter(a => a.id === 'doi').length > 0;
      if (!possuiAreaDoi && !ato.id) {
        ato.doi = this.getStaticDoi();
      }
      if (ato.doi && ato.doi.gerarDoi == undefined) {
        ato.doi.gerarDoi = possuiAreaDoi;
      }
      return ato.doi || this.getStaticDoi();
    },

    async getCamposAdicionais(ato) {

      if(ato.tipoServico?.regraCustas){
        ato.camposAdicionais = await RegraCustasBusiness.getRegra(ato.tipoServico.regraCustas).catch(e => null);
      }
      if (!this.areas.includes('emolumentos') && !ato.id) {
        ato.dto.camposAdicionais = {};
      }
      return ato.dto.camposAdicionais || {};
    },

    async getProcurador() {
      await this.form.envolvidos.map(async envolvido => {
        this.$set(envolvido, 'exibicao', this.procuradorExibicao(envolvido));
        this.$set(envolvido, 'procuradorVersao', envolvido.procuradorVersao || {});
        this.$set(envolvido, 'editandoProcurador', await this.validarDocumentos(envolvido, true));
      });
    },

    procuradorExibicao(envolvido) {
      return `${envolvido?.procuradorVersao?.nome ? `${envolvido?.procuradorVersao?.nome} - ` : ''}${envolvido.procurador}`;
    },

    editProcurador(envolvido) {
      this.$set(envolvido, 'editandoProcurador', !envolvido.editandoProcurador);
    },

    async selecionarProcurador(envolvido, indicador) {
      this.$set(envolvido, 'procurador', indicador?.documento);
      this.$set(envolvido, 'procuradorVersao', {id: indicador?.id, nome: indicador?.nome});
      this.$set(envolvido, 'exibicao', this.procuradorExibicao(envolvido));
      await this.validarDocumentos(envolvido)
      if (!indicador) this.$set(envolvido, 'procuradorDocumentoInvalido', null);
    },

    async validarDocumentos(envolvido, selecao = true) {
      let documentoProcurador = envolvido.procurador;
      if (!selecao) documentoProcurador = this.$refs.buscar[0].query;
      let msgDocInvalido = await IndicadorPessoalBusiness.validarDocumentos('FISICA', documentoProcurador);

      if (!selecao) {
        this.$set(envolvido, 'procurador', documentoProcurador);
        this.$set(envolvido, 'procuradorVersao', {});
        this.$set(envolvido, 'exibicao', this.procuradorExibicao(envolvido));
      }

      if (msgDocInvalido == null || (msgDocInvalido && (documentoProcurador == null || documentoProcurador == ""))) {
        this.$set(envolvido, 'procuradorDocumentoInvalido', null);
      } else {
        this.$set(envolvido, 'procuradorDocumentoInvalido', msgDocInvalido);
      }
    },

    changeEditor(e, campo, focus) {
      for (let checklist of (this.$refs?.checklist || [])) {
        checklist?.montarChecklist(e, campo, focus);
      }
    },

    async atualizarChecklists() {
      await this.tratarChecklist();
      this.$forceUpdate();
    },

    async tratarChecklist() {
      this.indices = this.form?.indices || {};
      this.checklist = ChecklistProcessamentoBusiness.getChecklists(this.form, 'ato');
      this.checklistEnvolvidos = ChecklistProcessamentoBusiness.getChecklists(this.form, 'envolvidos');
    },

    checkDate(campo) {
      if(new Date(this.form[campo]) > new Date(this.today)) {
        FrontBusiness.showError('', 'A data de ' + (campo == 'registro' ? 'Registro' : 'Selagem') + ' não pode ser maior que a data atual');
        this.$set(this.form, campo, null);
      }
      if (campo === 'selado') {
        if(new Date(this.form[campo]) < new Date(this.form['registro'])) {
          FrontBusiness.showError('', 'A data de Selagem não pode ser menor que a data de Registro');
          this.$set(this.form, campo, null);
        }
      }
      if (campo === 'registro') {
        if(new Date(this.form[campo]) > new Date(this.form['selado'])) {
          FrontBusiness.showError('', 'A data de Registro não pode ser maior que a data de Selagem');
          this.$set(this.form, campo, null);
        }
      }
    },



    async confirmarSalvarAlteracoes() {
      this.copiarAto = false;
      let result = await FrontBusiness.showConfirmacao("Você possui alterações no ato", "Deseja salvar?").then(async r => {
        if (r.value) {
          return await this.salvar();
        } else if (r.dismiss === 'cancel') {
          return false;
        } else {
          return true;
        }
      }).catch(this.showError);

      return result
    },

    limparDto(dto) {
      Object.keys(dto?.indices || {}).forEach(e => {
        if (Utils.isEmptyNullOrUndefined(dto?.indices?.[e]) || (!dto?.indices?.[e].length) || !(Object.keys(dto?.indices?.[e] || {}).length)) {
          delete dto?.indices?.[e];
        }
      })
      delete dto?.indices?.checklistProcessado;
      Object.keys(dto?.dto?.valoresBaseCalculo || {}).forEach(e => {
        if (Utils.isEmptyNullOrUndefined(dto?.dto?.valoresBaseCalculo?.[e])) {
          delete dto?.dto?.valoresBaseCalculo?.[e];
        }
      })
      if (Utils.isEmptyNullOrUndefined(dto?.dto?.valoresMultiplasCobrancas) || !dto?.dto?.valoresMultiplasCobrancas.length) {
        delete dto?.dto?.valoresMultiplasCobrancas;
      }
      if (Utils.isEmptyNullOrUndefined(dto?.dto?.restricoesCanceladasSemRegistrar) || !dto?.dto?.restricoesCanceladasSemRegistrar.length) {
        delete dto?.dto?.restricoesCanceladasSemRegistrar;
      }
      if (Utils.isEmptyNullOrUndefined(dto?.dto?.restricoesCanceladas) || !dto?.dto?.restricoesCanceladas.length) {
        delete dto?.dto?.restricoesCanceladas;
      }

      if (Utils.isEmptyNullOrUndefined(dto?.ressarcimento) || !dto?.ressarcimento.length) {
        delete dto?.ressarcimento;
      }
      Utils.orderFields(dto?.envolvidos, ['ordem', 'nome']);
      (dto?.envolvidos || []).map((e, i) => {
        e.ordem = i + 1
      })
      return dto
    },

    async verificarAlteracoes(armazenar) {
      let dto = Object.freeze(Utils.clone(this.limparDto(await this.gerarDto())));
      if(!this.form_old || armazenar){
        this.form_old = dto;
      }

      if(armazenar){
        return false;
      }

      let changed = !Utils.isEqual(dto, this.form_old);
      if(changed){
        console.debug('verificarAlteracoes', Utils.deepDiff(dto, this.form_old), !Utils.isEqual(dto, this.form_old));
      }

      return changed;
    },

    async gerarTexto(){
      let salvar = false;
      if (await this.verificarAlteracoes()) {
        salvar = await this.confirmarSalvarAlteracoes();
      } else {
        salvar = true;
      }

      if(salvar) {
        const modal = IndicadorBusiness.getSimulacaoIndicador(this.form.ficha.livro);
        let retorno = await FrontBusiness.openModal(modal, {
          ato: this.form?.id,
          id: this.form?.ficha.id,
          protocolo: this.form.protocolo
        });

        if (retorno) {
          if (!this.form.texto) {
            this.$set(this.form, 'texto', retorno.minuta);
          } else {
            let change = await FrontBusiness.openModal(ModalCompararTexto, {
              original: this.form.texto,
              novo: retorno.minuta
            });
            if (change) {
              this.$set(this.form, 'texto', retorno.minuta);
            }
          }
        }
      }
    },

    async consultarModelos(){
      let modelo = await FrontBusiness.openModal(ModalSelecionarModeloTextual, {tipo : 'ATO', dominio: this.dominio});
      this.insertContent(modelo.texto);
    },

    async consultarQualificacoes(){
      let modelo = await FrontBusiness.openModal(ModalInserirQualificacao,
        {envolvidos : this.form?.envolvidos?.map(e => ({id : e.indicadorPessoalVersao.id}))}
      );
      this.insertContent(modelo);
    },

    insertContent(text){
      if(text){
        this.$refs.editorminuta?.$refs?.editortexto?.editor?.insertContent(text);
      }
    },

    selecionarFichas(fichas) {
      this.$set(this.form, 'ficha', fichas ? fichas[0] : null);
      if(!this.form.ficha){
        this.$refs.buscarFichas.limparCampos();
      }
    },

    async pesquisandoFicha(pesquisando) {
      await this.$set(this, 'pesquisando', pesquisando);
    }

  }
}
</script>

<style lang=scss>
#detalhes-ato {
  .modal-body {
    height: calc(100vh - 150px);
  }
}
</style>
